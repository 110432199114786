<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        elevation="0"
        @click="changeTheme()"
        class="px-2 transparent"
        v-bind="attrs"
        v-on="on"
        min-width="auto"
      >
        <v-icon
          large
          :color="$store.getters.theme === 'dark' ? 'yellow' : 'white'"
        >
          mdi-theme-light-dark
        </v-icon>
      </v-btn>
    </template>
    <span>{{ $store.getters.theme === "dark" ? "Dark" : "Light" }}</span>
  </v-tooltip>
</template>

<script>
export default {
  methods: {
    changeTheme() {
      if (this.$store.getters.theme === "dark") {
        localStorage.setItem("glb_theme", "LIGHT_THEME");
        this.$store.commit("LIGHT_THEME");
      } else {
        localStorage.setItem("glb_theme", "DARK_THEME");
        this.$store.commit("DARK_THEME");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.theme-btn {
  box-shadow: 0px 0px 0px 2px white !important;
  border-radius: 5px;
}
</style>