<template>
    <div className="logoImg" title="Hashtag.space">
        <div
            :class="$store.getters.theme === 'dark' ? 'logoImg' : 'logoImg-d'"
            :style="getLogo"
        ></div>
    </div>
</template>

<script>
export default {
    data: () => ({}),
    computed: {
        getLogo: function () {
            if (this.$store.getters.theme == "dark")
                return {
                    backgroundImage: `url(${require("@/assets/space-logo-white.gif")})`,
                };

            return {
                backgroundImage: `url(${require("@/assets/space-logo-black.gif")})`,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    z-index: 1;
}
.logoImg {
    width: 270px;
    height: 75px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    margin: 0 12px 0 20px;
    // path {
    //   fill: white;
    // }
}
.logoImg-d {
    width: 270px;
    height: 75px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    margin: 0 12px 0 20px;
    // path {
    //   fill: white;
    // }
}

@media (max-width: 1620px) {
    .logoImg,
    .logoImg-d {
        width: 260px !important;
        height: 70px !important;
    }
}

@media (max-width: 1530px) {
    .logoImg,
    .logoImg-d {
        width: 245px !important;
        height: 60px !important;
    }
}

@media (max-width: 1366px) {
    .logoImg,
    .logoImg-d {
        width: 230px !important;
        height: 55px !important;
    }
}
</style>
