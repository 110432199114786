<template>
  <v-app
    :class="$store.getters.theme === 'dark' ? 'dark-theme' : 'light-theme'"
  >
    <v-app-bar app height="100px" elevation="0" dark color="transparent">
      <logo></logo>
      <v-spacer></v-spacer>
      <theme-change></theme-change>
    </v-app-bar>

    <v-main :style="authBg" app class="auth-bg pt-0">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Logo from "../global-components/Logo.vue";
import ThemeChange from "../global-components/ThemeChange.vue";

export default {
  components: { Logo, ThemeChange },
  data: () => ({}),
  computed: {
    authBg: function () {
      if (this.$store.getters.theme === "dark")
        return {
          backgroundImage: `url(${require("@/assets/hashtag-bg.jpeg")})`,
        };
      return {
        backgroundImage: `url(${require("@/assets/main-bg-w-mini.jpg")})`,
      };
    },
  },
};
</script>

<style scoped>
.auth-bg {
  height: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: right top;
}

.dark-theme {
  background: #04081c;
}

.light-theme {
  background: #e7eef7;
}

::v-deep .v-toolbar__content {
  height: auto !important;
}
</style>
